<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="flex-col w-11/12 mx-auto mt-4" v-if="!isLoading">
      <div
        class="flex-col w-full border-2 rounded-lg border-green-1002 p-3 mb-8"
      >
        <div class="flex justify-between items-center mb-3">
          <h2 class="text-2xl font-bold">Formações Acadêmicas</h2>
          <router-link
            class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white"
            :to="{ name: 'create-academic-background' }"
          >
            <i class="text-2xl text-green-1002 ri-add-fill"></i>
          </router-link>
        </div>
        <div
          v-for="academicBackground in academicBackgrounds"
          class="py-1"
          :key="academicBackground.id"
        >
          <div class="flex items-center">
            <div class="flex flex-col flex-grow">
              <h3 class="text-xl font-bold">
                {{ academicBackground.institution }}
              </h3>
              <p>{{ academicBackground.formation }}</p>
            </div>
            <div class="flex">
              <router-link
                :to="{
                  name: 'edit-academic-background',
                  params: { id: academicBackground.id }
                }"
                class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4"
              >
                <i class="text-2xl text-green-1002 ri-pencil-fill"></i>
              </router-link>
              <button
                @click.prevent="deleteAcademicBackground(academicBackground.id)"
                class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white"
              >
                <i class="text-2xl text-green-1002 ri-delete-bin-fill"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-col w-full border-2 rounded-lg border-green-1002 p-3 mb-8"
      >
        <div class="flex justify-between items-center mb-3">
          <h2 class="text-2xl font-bold">Experiências</h2>
          <router-link
            class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white"
            :to="{ name: 'create-experience' }"
          >
            <i class="text-2xl text-green-1002 ri-add-fill"></i>
          </router-link>
        </div>
        <div
          v-for="experience in experiences"
          class="py-1"
          :key="experience.id"
        >
          <div class="flex items-center">
            <div class="flex flex-col flex-grow">
              <h3 class="text-xl font-bold">{{ experience.company }}</h3>
              <p>{{ experience.title }}</p>
            </div>
            <div class="flex">
              <router-link
                :to="{ name: 'edit-experience', params: { id: experience.id } }"
                class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4"
              >
                <i class="text-2xl text-green-1002 ri-pencil-fill"></i>
              </router-link>
              <button
                @click.prevent="deleteExperience(experience.id)"
                class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white"
              >
                <i class="text-2xl text-green-1002 ri-delete-bin-fill"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex-col w-full border-2 rounded-lg border-green-1002 p-3 mb-8"
      >
        <div class="flex justify-between items-center mb-3">
          <h2 class="text-2xl font-bold">Cursos Complementares</h2>
          <router-link
            :to="{ name: 'create-complementary-course' }"
            class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white"
          >
            <i class="text-2xl text-green-1002 ri-add-fill"></i>
          </router-link>
        </div>
        <div
          v-for="complementaryCourse in complementaryCourses"
          class="py-1"
          :key="complementaryCourse.id"
        >
          <div class="flex items-center">
            <div class="flex flex-col flex-grow">
              <h3 class="text-xl font-bold">
                {{ complementaryCourse.issuer }}
              </h3>
              <p>
                {{ complementaryCourse.name }},
                {{ complementaryCourse.workload }} horas
              </p>
            </div>
            <div class="flex">
              <router-link
                :to="{
                  name: 'edit-complementary-course',
                  params: { id: complementaryCourse.id }
                }"
                class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4"
              >
                <i class="text-2xl text-green-1002 ri-pencil-fill"></i>
              </router-link>
              <button
                @click.prevent="
                  deleteComplementaryCourse(complementaryCourse.id)
                "
                class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white"
              >
                <i class="text-2xl text-green-1002 ri-delete-bin-fill"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex-col w-full border-2 rounded-lg border-green-1002 p-3 mb-8"
      >
        <div class="flex justify-between items-center mb-3">
          <h2 class="text-2xl font-bold">Áreas de Interesse</h2>
          <router-link
            class="border-2 border-green-1002 py-1 px-4 rounded-md bg-white mr-4"
            :to="{ name: 'edit-interest-areas' }"
          >
            <i class="text-2xl text-green-1002 ri-pencil-fill"></i>
          </router-link>
        </div>
        <div>
          {{ interestAreas }}
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import { mapGetters } from 'vuex';
import LoaderAnimation from '../../../../components/LoaderAnimation.vue';

export default {
  name: 'CurriculumIndex',

  title() {
    return `${process.env.VUE_APP_NAME} | Currículo Complementar`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  data() {
    return {
      isLoading: true,
      academicBackgrounds: [],
      experiences: [],
      complementaryCourses: [],
      interestAreas: null
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },

  methods: {
    async fetchAcademicBackgrounds() {
      const response = await axios.get(
        `/api/formacoes-academicas/${this.user.participant_id}`
      );

      this.academicBackgrounds = response.data.data;
      this.isLoading = false;
    },

    async fetchExperiences() {
      const response = await axios.get(
        `/api/experiencias/${this.user.participant_id}`
      );

      this.experiences = response.data.data;
    },

    async fetchComplementaryCourses() {
      const response = await axios.get(
        `/api/cursos-complementares/${this.user.participant_id}`
      );

      this.complementaryCourses = response.data.data;
    },

    async fetchInterestAreas() {
      const response = await axios.get(
        `/api/areas-de-interesse/${this.user.participant_id}`
      );

      this.interestAreas = response.data.data
        .map(interestArea => interestArea.label)
        .join(', ');
    },

    async updateProfile(id) {
      const participant = await axios.get('/api/perfil');

      axios
        .patch('/api/perfil', {
          education_level_id: id,
          telephone: participant.data.data.telephone,
          cellphone: participant.data.data.cellphone,
          birth_date: participant.data.data.birthDate,
          gender: participant.data.data.gender.value
        })
        .then(({ data }) => {
          return data.data.message;
        });
    },

    async syncInformation() {
      const participant = await axios.get('/api/perfil');

      let temp = 0;
      let educationLevel = [];

      if (participant.data.data.academicBackgrounds.length == 0) {
        this.updateProfile(14);
      } else {
        participant.data.data.academicBackgrounds.forEach(
          academicBackgrounds => {
            if (academicBackgrounds.education_level.level > temp) {
              temp = academicBackgrounds.education_level.level;
              educationLevel = academicBackgrounds.education_level;
            }
          }
        );
        this.updateProfile(educationLevel.id);
      }
    },

    deleteAcademicBackground(id) {
      this.$confirm({
        message: 'Tem certeza que deseja excluir esta Formação Acadêmica?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            axios.delete(`/api/formacoes-academicas/${id}`).then(({ data }) => {
              this.$toast.success(data.data.message);

              this.academicBackgrounds = this.academicBackgrounds.filter(
                academicBackground => {
                  return academicBackground.id !== id;
                }
              );
            });

            setTimeout(() => {
              this.$router.go(0);
            }, 2000);
          }
        }
      });
    },

    deleteComplementaryCourse(id) {
      this.$confirm({
        message: 'Tem certeza que deseja excluir este Curso Complementar?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            axios
              .delete(`/api/cursos-complementares/${id}`)
              .then(({ data }) => {
                this.$toast.success(data.data.message);

                this.complementaryCourses = this.complementaryCourses.filter(
                  complementaryCourse => {
                    return complementaryCourse.id !== id;
                  }
                );
              });
          }
        }
      });
    },

    deleteExperience(id) {
      this.$confirm({
        message: 'Tem certeza que deseja excluir esta Experiência?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            axios.delete(`/api/experiencias/${id}`).then(({ data }) => {
              this.$toast.success(data.data.message);

              this.experiences = this.experiences.filter(experience => {
                return experience.id !== id;
              });
            });
          }
        }
      });
    }
  },

  created() {
    this.syncInformation();
    this.fetchAcademicBackgrounds();
    this.fetchExperiences();
    this.fetchComplementaryCourses();
    this.fetchInterestAreas();
  }
};
</script>

<style scoped></style>
